/* eslint-disable max-len */

import React from "react";

function ArrowDownIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 18 18"
      width="17px"
      height="17px"
      className="l6r__svg-icon"
    >
      <path d="M10.293 1.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L6.415 8l5.292 5.293c.36.36.388.928.083 1.32l-.083.094c-.36.36-.928.388-1.32.083l-.094-.083-6-6c-.36-.36-.388-.928-.083-1.32l.083-.094 6-6z" transform="translate(-20 -536) translate(0 516) rotate(-90 28 16) translate(7 9)" />
    </svg>
  );
}

export default ArrowDownIcon;
