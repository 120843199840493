/* eslint-disable max-len */

import React from "react";

function CashierIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
      className="l6r__svg-icon"
      fill="white"
    >
      <path d="M13.61 14.25c.767 0 1.39-.589 1.39-1.313v-7c0-.723-.623-1.312-1.39-1.312H3.188c-.241 0-.437-.196-.437-.438 0-.241.196-.437.437-.437h10.5c.242 0 .438-.196.438-.438 0-.724-.588-1.312-1.313-1.312H2.75C1.783 2 1 2.783 1 3.75v8.75c0 .967.783 1.75 1.75 1.75h10.86zm-1.235-3.938c-.483 0-.875-.391-.875-.875 0-.483.392-.875.875-.875s.875.392.875.875c0 .484-.392.875-.875.875z" transform="translate(-61 -374) translate(49 79) translate(5 287) translate(7 8)" />
    </svg>
  );
}

export default CashierIcon;
