/* eslint-disable max-len */

import React from "react";

function HelpChatIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 17 16"
      width="17px"
      height="16px"
      className="l6r__svg-icon"
    >
      <path d="M8.125 1c2.895 0 5.25 2.355 5.25 5.25v1.971c-.002 1.689-1.374 1.527-3.063 1.529H7.687c-.483 0-.875-.392-.875-.875S7.204 8 7.687 8h.875c.483 0 .875.392.875.875h.875c1.207-.001 2.188.173 2.188-.654V6.25c0-2.412-1.963-4.375-4.375-4.375-1.92 0-3.555 1.243-4.143 2.967.127.155.202.352.202.567v.902c0 .498-.404.902-.902.902s-.902-.404-.902-.902v-.902c0-.461.345-.841.792-.896C3.889 2.47 5.839 1 8.125 1zM5.947 8.97c-.002-.032-.01-.062-.01-.095.002-.965.786-1.749 1.75-1.75h.875c.558 0 1.237.392 1.516.875h.234c.724 0 1.312-.589 1.313-1.313V6.25c0-1.932-1.568-3.5-3.5-3.5s-3.5 1.568-3.5 3.5c0 1.105.522 2.078 1.322 2.72zM13.375 15c.483 0 .875-.392.875-.875 0-1.932-1.568-3.5-3.5-3.5h-.632c-.523.241-1.416.437-1.993.437-.577 0-1.47-.196-1.993-.437H5.5c-1.932 0-3.5 1.568-3.5 3.5 0 .483.392.875.875.875h10.5z" transform="translate(-422 -93) translate(56 78) translate(346 8) translate(20.108 7)" />
    </svg>
  );
}

export default HelpChatIcon;
