/* eslint-disable max-len */

import React from "react";

function HomeIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
      className="l6r__svg-icon"
    >
      <path d="M6.25 13.875c.482-.001.874-.393.875-.875v-2.844h1.75V13c.001.482.393.874.875.875h3.5c.478-.001.87-.39.875-.868V7.905l.753.619c.067.056.193.101.28.101.113 0 .263-.07.336-.156l.28-.336c.056-.067.102-.193.102-.281 0-.114-.071-.264-.159-.337l-1.592-1.31V3.595c0-.121-.098-.219-.219-.219h-.875c-.118 0-.215.097-.217.215v1.538L8.98 1.98c-.235-.195-.675-.354-.981-.354-.306 0-.746.159-.98.354L.281 7.515c-.086.073-.156.223-.156.336 0 .087.045.213.102.28l.28.337c.072.086.222.156.335.156.088 0 .214-.045.28-.102l.752-.616V13c.001.482.393.874.875.875h3.5zm-.438-1.312H3.187V6.828L8 2.873l4.812 3.953-.004 5.737h-2.62V9.719c-.002-.482-.394-.874-.876-.875H6.687c-.481.001-.873.393-.875.875v2.844z" transform="translate(-129 -109) translate(105 95) translate(24 14)" />
    </svg>
  );
}

export default HomeIcon;
