/* eslint-disable max-len */

import React from "react";

function HeartIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
      className="l6r__svg-icon"
    >
      <path d="M13.641 2.462c-1.498-1.277-3.727-1.048-5.102.372L8 3.389l-.539-.555c-1.372-1.42-3.604-1.65-5.102-.372C.642 3.927.552 6.558 2.089 8.146l5.29 5.464a.857.857 0 0 0 1.239 0l5.29-5.464c1.54-1.588 1.45-4.219-.267-5.684z" fill="#FFF" fillRule="nonzero" />
    </svg>
  );
}

export default HeartIcon;
