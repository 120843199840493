/* eslint-disable max-len */

import React from "react";

function HeartOutlineIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-6 0 16 16"
      width="16px"
      height="16px"
      className="l6r__svg-icon"
    >
      <path d="M7.534 2.508C5.954 1.18 3.512 1.378 2 2.918c-1.512-1.54-3.954-1.741-5.534-.41-2.057 1.731-1.756 4.553-.29 6.049l4.796 4.886a1.434 1.434 0 0 0 2.056.003l4.796-4.887c1.463-1.495 1.77-4.317-.29-6.05zM6.89 7.635l-4.796 4.886c-.066.066-.12.066-.186 0l-4.796-4.886c-.998-1.017-1.2-2.942.2-4.12 1.063-.895 2.704-.76 3.732.287L2 4.778l.957-.976c1.034-1.053 2.674-1.182 3.732-.29 1.398 1.178 1.19 3.114.2 4.123z" fill="#FFF" fillRule="nonzero" />
    </svg>
  );
}

export default HeartOutlineIcon;
