/* eslint-disable max-len */

import React from "react";

function ExitFullscreenIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 20"
      width="17px"
      height="16px"
      className="l6r__svg-icon"
    >
      <path d="M1.66 11.406a.411.411 0 0 0-.41.41v1.105c0 .226.185.41.41.41h3.133v3.134c0 .225.184.41.41.41h1.106c.225 0 .41-.185.41-.41v-4.238a.818.818 0 0 0-.82-.82H1.66zM18.34 9.22c.225 0 .41-.185.41-.41V7.703a.411.411 0 0 0-.41-.41h-3.133V4.16a.411.411 0 0 0-.41-.41h-1.106a.411.411 0 0 0-.41.41v4.238c0 .455.366.82.82.82h4.239zm0 2.187c.225 0 .41.185.41.41v1.105c0 .226-.185.41-.41.41h-3.133v3.134c0 .225-.184.41-.41.41h-1.106a.411.411 0 0 1-.41-.41v-4.238c0-.455.366-.82.82-.82h4.239zM6.309 3.75c.225 0 .41.185.41.41v4.238c0 .455-.366.82-.82.82H1.66a.411.411 0 0 1-.41-.41V7.704c0-.225.185-.41.41-.41h3.133V4.16c0-.225.184-.41.41-.41h1.106z" fill="#FFF" fillRule="nonzero" />
    </svg>
  );
}

export default ExitFullscreenIcon;
