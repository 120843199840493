/* eslint-disable max-len */

import React from "react";

function FullscreenIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 17 16"
      width="17px"
      height="16px"
      className="l6r__svg-icon"
    >
      <path d="M5.047 13.5c.18 0 .328-.148.328-.328v-.884c0-.18-.148-.328-.328-.328H2.54V9.453c0-.18-.148-.328-.328-.328h-.885c-.18 0-.328.148-.328.328v3.39c0 .364.293.657.656.657h3.39zM10.953 3c-.18 0-.328.148-.328.328v.884c0 .18.148.328.328.328h2.506v2.507c0 .18.148.328.328.328h.885c.18 0 .328-.148.328-.328v-3.39c0-.364-.293-.657-.656-.657h-3.39zm3.719 6.125c.18 0 .328.148.328.328v3.39c0 .364-.293.657-.656.657h-3.39c-.181 0-.329-.148-.329-.328v-.884c0-.18.148-.328.328-.328h2.506V9.453c0-.18.148-.328.328-.328h.885zM5.047 3c.18 0 .328.148.328.328v.884c0 .18-.148.328-.328.328H2.54v2.507c0 .18-.148.328-.328.328h-.885c-.18 0-.328-.148-.328-.328v-3.39C1 3.292 1.293 3 1.656 3h3.39z" transform="translate(-254 -92) translate(56 78) translate(7.437 7.333) translate(168.169) translate(23.042 6.667)" />
    </svg>
  );
}

export default FullscreenIcon;
