/* eslint-disable max-len */

import React from "react";

function PhoneIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 10 10"
      width="10px"
      height="10px"
      className="l6r__svg-icon"
    >
      <path fillRule="nonzero" d="M9.78 7.91L8.23 6.36a.8.8 0 00-1.13.02l-.78.78-.16-.08A7.74 7.74 0 014.3 5.72c-.7-.71-1.08-1.39-1.35-1.88l-.09-.15.53-.52.25-.26a.8.8 0 00.02-1.14L2.11.22A.8.8 0 00.97.25L.54.68.55.7a2.52 2.52 0 00-.52 1.27c-.2 1.7.57 3.25 2.67 5.35 2.9 2.9 5.23 2.68 5.33 2.67a2.6 2.6 0 001.27-.52l.01.01.45-.43a.8.8 0 00.02-1.14z" />
    </svg>
  );
}

export default PhoneIcon;
