/* eslint-disable max-len */

import React from "react";

function DepositPopupBalanceContentIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      fill="none"
      className="l6r__svg-icon"
    >
      <path
        fill="#f7b500"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.082c0 .506-.445.918-.992.918H1.25C.56 9 0 8.452 0 7.776V1.653C0 .977.56.43 1.25.43h7.188c.517 0 .937.41.937.918a.31.31 0 0 1-.313.306h-7.5a.31.31 0 0 0-.312.306c0 .17.14.306.313.306h7.445c.547 0 .992.412.992.919v4.898ZM7.857 5.429a.714.714 0 1 0 1.429 0 .714.714 0 0 0-1.429 0Z"
      />
    </svg>
  );
}

function OrdersPopupIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className="l6r__svg-icon"
    >
      <path
        fill="#ffb124"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a1 1 0 0 0 0 2h1v2.062A8.001 8.001 0 0 0 12 22a8 8 0 0 0 6.112-13.162l.01-.01.706-.707a1 1 0 1 0-1.414-1.414l-.707.707a1.018 1.018 0 0 0-.064.07A7.956 7.956 0 0 0 13 6.063V4h1a1 1 0 1 0 0-2h-4Zm2 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm1-9a1 1 0 1 0-2 0v3.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13 13.586V11Z"
      />
    </svg>
  );
}

function SuccessIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className="l6r__svg-icon"
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke="#2c8a30"
        strokeWidth="2"
      />
      <path
        stroke="#2c8a30"
        strokeLinecap="round"
        strokeWidth="2"
        d="m8 12.5 2.5 2.5L16 9"
      />
    </svg>
  );
}

function ReloadIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className="l6r__svg-icon"
    >
      <path
        fill="#fff"
        d="M8 14.095a6.073 6.073 0 0 0 4.085-1.57.296.296 0 0 0 .011-.429l-.485-.485a.296.296 0 0 0-.405-.014A4.8 4.8 0 0 1 8 12.818 4.815 4.815 0 0 1 3.183 8 4.815 4.815 0 0 1 8 3.183c1.331 0 2.536.538 3.407 1.41l-1.33 1.33a.295.295 0 0 0 .208.504h3.516a.295.295 0 0 0 .295-.295V2.617a.295.295 0 0 0-.504-.209L12.31 3.69A6.075 6.075 0 0 0 8 1.905c-3.359 0-6.098 2.74-6.095 6.1A6.095 6.095 0 0 0 8 14.094Z"
      />
    </svg>
  );
}

function ErrorIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className="l6r__svg-icon"
    >
      <path
        stroke="#b12509"
        strokeWidth="2"
        d="M10.862 3.653c.488-.87 1.788-.87 2.276 0l8.704 15.516c.445.794-.12 1.831-1.138 1.831H3.296c-1.017 0-1.583-1.037-1.138-1.831l8.704-15.516Z"
      />
      <rect
        width="2"
        height="7"
        x="11"
        y="8"
        fill="#b12509"
        rx="1"
      />
      <rect
        width="2"
        height="2"
        x="11"
        y="16"
        fill="#b12509"
        rx="1"
      />
    </svg>
  );
}

export default {
  DepositPopupBalanceContentIcon,
  OrdersPopupIcon,
  SuccessIcon,
  ReloadIcon,
  ErrorIcon,
};
