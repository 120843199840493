/* eslint-disable max-len */

import React from "react";

function ReloadIcon () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 17 16"
      width="17px"
      height="16px"
      className="l6r__svg-icon"
    >
      <path d="M8 14.095c1.571 0 3.004-.594 4.084-1.57.126-.114.132-.31.012-.429l-.485-.485c-.11-.11-.289-.118-.405-.013-.852.758-1.974 1.22-3.206 1.22-2.662 0-4.817-2.155-4.817-4.818 0-2.662 2.154-4.817 4.817-4.817 1.331 0 2.535.538 3.407 1.41l-1.33 1.33c-.186.186-.055.504.208.504H13.8c.163 0 .295-.132.295-.295V2.617c0-.263-.317-.395-.503-.209L12.31 3.69C11.207 2.587 9.683 1.905 8 1.905c-3.359 0-6.098 2.74-6.095 6.1.002 3.364 2.73 6.09 6.095 6.09z" transform="translate(-155 -92) translate(56 78) translate(7.437 7.333) translate(84.085) translate(8.042 6.667)" />
    </svg>
  );
}

export default ReloadIcon;
